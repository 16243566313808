import { useState } from "react";

export const TopList = (props: { quoters: any; quotees: any }) => {
    const [state, setState] = useState<"open" | "closed">("closed");
    return (
        <div className="toplist">
            <h2
                onClick={() => {
                    setState(state === "open" ? "closed" : "open");
                }}>
                Top list
            </h2>
            {state === "open" && (
                <div className="list-container">
                    <div className="quoters">
                        <h3>Most quotes added</h3>
                        <ul>
                            {props.quoters.map((q: any) => {
                                return (
                                    <li>
                                        <span className="user">{q.user}</span>
                                        <span className="number">{q.quotes}</span>
                                    </li>
                                );
                            })}
                        </ul>
                    </div>
                    <div className="quotees">
                        <h3>Most quoted</h3>
                        <ul>
                            {props.quotees.map((q: any) => {
                                return (
                                    <li>
                                        <span className="user">{q.user}</span>
                                        <span className="number">{q.quotes}</span>
                                    </li>
                                );
                            })}
                        </ul>
                    </div>
                </div>
            )}
        </div>
    );
};
