import { useEffect, useState } from "react";
import { Card } from "./Card";
import { Helmet } from "react-helmet";
// import { response } from "../../utils/devApiResponse";
import Select from "react-select";
import "./Quotes.css";
import { sortObject } from "../../utils/sortObject";
import { Admin } from "../admin/Admin";
import { Logs } from "../admin/Logs";
import { TopList } from "./topList";

export const Quotes = (props: any) => {
    const [data, setData] = useState<any>(null);
    const [users, setUsers] = useState<any>(null);
    const [channels, setChannels] = useState<any>(null);
    const [filteredQuotes, setFilteredQuotes] = useState<any>(null);
    const [selectedUser, setSelectedUser] = useState<any>(null);
    const [selectedChannel, setSelectedChannel] = useState<any>(null);
    const [random, setRandom] = useState<any>(null);
    const [single, setSingle] = useState<any>(null);
    const [title, setTitle] = useState<string>("Dunderpatrullen Quotes");
    const [admin, setAdmin] = useState(false);
    const [authenticated, setAuthenticated] = useState(false);
    const [user, setUser] = useState<any>(false);
    const [token, setToken] = useState<string>("");
    const [view, setView] = useState<"quotes" | "logs">("quotes");
    const [quoters, setQuoters] = useState<any>(false);
    const [quotees, setQuotees] = useState<any>(false);
    useEffect(() => {
        // if (!selectedUser) {
        //     const params = new URLSearchParams(window.location.search);
        //     const user = params.get("user");
        //     if (user) {
        //         setSelectedUser(user);
        //         console.log(user);
        //     }
        // }
        if (selectedUser) {
            if (selectedUser.value) {
                let quotes = [...data.quotes];
                const newFilteredQuotes = quotes.filter((quote) => quote.author === selectedUser.value);
                setFilteredQuotes(newFilteredQuotes);
                setSelectedChannel("");
                setSingle(false);
            } else {
                setFilteredQuotes([...data.quotes]);
                setSelectedChannel("");
            }
        }
    }, [selectedUser]);

    useEffect(() => {
        // if (!selectedChannel) {
        //     const params = new URLSearchParams(window.location.search);
        //     const user = params.get("user");
        //     if (user) {
        //         setSelectedUser(user);
        //         console.log(user);
        //     }
        // }
        if (selectedChannel) {
            if (selectedChannel.value) {
                let quotes = [...data.quotes];
                const newFilteredQuotes = quotes.filter((quote) => quote.channelId === selectedChannel.value);
                setFilteredQuotes(newFilteredQuotes);
                setSelectedUser("");
                setSingle(false);
            } else {
                setFilteredQuotes([...data.quotes]);
                setSelectedUser("");
            }
        }
    }, [selectedChannel]);

    useEffect(() => {
        const getQuotes = async () => {
            // PROD
            const q = await fetch("/.netlify/functions/api-proxy/api");
            // const q = await fetch("/api/pixbot/quotes");
            const quotes = await q.json();
            //DEV
            // const quotes = response;
            setData(quotes);
        };
        if (!data) {
            getQuotes();
        } else {
            const quotesArr = data.quotes;
            quotesArr.sort((a: any, b: any) => {
                const tsa = a.timestamp;
                const tsb = b.timestamp;
                if (tsa > tsb) {
                    return -1;
                }
                if (tsa < tsb) {
                    return 1;
                }
                return 0;
            });
            // console.log("SORTED!");
            setFilteredQuotes(quotesArr);
            const search = document.location.search;
            if (search === "?random") {
                setRandom(quotesArr[Math.floor(Math.random() * (quotesArr.length - 1))]);
            } else if (search.indexOf("?id") !== -1) {
                const id = search.split("=")[1];
                let quotes = [...data.quotes];
                const singleQuote = quotes.filter((quote: any) => quote.id === id);
                console.log(singleQuote);
                const user = data.users[singleQuote[0].author];
                setTitle(`A quote from @${user}`);
                setFilteredQuotes(singleQuote);
                setSingle(true);
            }
            // const paginatedQuotes: { [key: number]: any } = {};
            // console.log(filteredQuotes);
            // filteredQuotes.forEach((fq: any, i: number) => {
            //     const currentQuote = i + 1;
            //     const page = Math.floor(currentQuote / 10);
            // });
        }
    }, [data]);

    useEffect(() => {
        if (!users && data) {
            let usersObj: { [key: string]: any } = {};
            Object.keys(data.users).forEach((user: any) => {
                usersObj[data.users[user]] = user;
            });
            const sortedUsers = sortObject(usersObj);
            setUsers(sortedUsers);
        }
    }, [users, data]);

    useEffect(() => {
        if (!channels && data) {
            let channelsObj: { [key: string]: any } = {};
            Object.keys(data.channels).forEach((channel: any) => {
                channelsObj[data.channels[channel]] = channel;
            });
            const sortedChannels = sortObject(channelsObj);
            setChannels(sortedChannels);
        }
    }, [channels, data]);

    //User handling
    useEffect(() => {
        const handleUser = async (accessToken: string) => {
            const req = await fetch("/.netlify/functions/api-proxy/getUser/" + accessToken);
            const json = await req.json();
            console.log(json);
            if (!json.id) {
                localStorage.removeItem("token");
                setUser(false);
                setToken("");
                setAdmin(false);
                setAuthenticated(false);
            }
            setUser(json);
            if (json.admin) {
                setAdmin(true);
            }
        };
        const fragment = new URLSearchParams(window.location.hash.slice(1));
        let [accessToken, tokenType] = [fragment.get("access_token"), fragment.get("token_type")];
        const nextUrl = process.env.NODE_ENV === "development" ? "http://localhost:8888/" : "https://dunder.kweh.se";
        if (localStorage.getItem("token")) {
            accessToken = localStorage.getItem("token");
        }
        if (accessToken) {
            setAuthenticated(true);
            setToken(accessToken);
            localStorage.setItem("token", accessToken);
            window.history.replaceState({}, "", nextUrl);
        }
        if (authenticated && token) {
            handleUser(token);
        }
    }, [authenticated]);

    const handleAuthentication = () => {
        let redir = "https://dunder.kweh.se";
        if (process.env.NODE_ENV === "development") {
            redir = "http://localhost:8888/";
        }
        document.location.href = `https://discord.com/api/oauth2/authorize?client_id=940181862834376714&redirect_uri=${redir}&response_type=token&scope=identify`;
    };

    let userOptions: any[] = [];
    if (users) {
        userOptions.push({ label: "All users", value: "" });
        Object.keys(users).forEach((user: string) => {
            userOptions.push({ label: user, value: users[user] });
        });
    }

    let channelOptions: any[] = [];
    if (users) {
        channelOptions.push({ label: "All channels", value: "" });
        Object.keys(channels).forEach((channel: string) => {
            channelOptions.push({ label: "#" + channel, value: channels[channel] });
        });
    }

    const handleSelectUser = (value: any, action: any) => {
        console.log(value);
        setSelectedUser(value);
    };
    const handleSelectChannel = (value: any, action: any) => {
        console.log(value);
        setSelectedChannel(value);
    };

    const selectStyle = {
        control: (baseStyles: any, state: any) => ({
            ...baseStyles,
            backgroundColor: "#000",
            border: "1px solid #FFFFFF55",
        }),
        valueContainer: (baseStyles: any, state: any) => ({
            ...baseStyles,
            background: "#000",
        }),
        menu: (baseStyles: any, state: any) => ({
            ...baseStyles,
            borderRadius: ".5rem",
        }),
        menuList: (baseStyles: any, state: any) => ({
            ...baseStyles,
            background: "#000",
            color: "#FFFFFFcc",
            borderRadius: ".5rem",
            overflowY: "scroll",
            overflowX: "hidden",
            border: "1px solid transparent",
        }),
        option: (baseStyles: any, state: any) => ({
            ...baseStyles,
            background: state.isFocused ? "#f3114e" : "#000",
            color: state.isFocused ? "#ffffffcc" : "#f3114e",
        }),
    };

    const handleViewChange = (newView: "quotes" | "logs") => {
        setView(newView);
    };
    useEffect(() => {
        if (filteredQuotes) {
            // QUOTERS
            const adders = filteredQuotes.map((q: any) => data.users[q.addedBy]);
            const addersNumbers: { [key: string]: any } = {};
            adders.forEach((u: any) => {
                const userArr = adders.filter((a: any) => a === u);
                addersNumbers[u] = userArr.length;
            });
            const addersArr = [];
            for (const u in addersNumbers) {
                addersArr.push({ user: u, quotes: addersNumbers[u] });
            }
            addersArr.sort((a: any, b: any) => (a.quotes < b.quotes ? 1 : -1));
            setQuoters(addersArr);

            // QUOTEES
            const authors = filteredQuotes.map((q: any) => data.users[q.author]);
            const authorsNumbers: { [key: string]: any } = {};
            authors.forEach((u: any) => {
                const userArr = authors.filter((a: any) => a === u);
                authorsNumbers[u] = userArr.length;
            });
            const authorsArr = [];
            for (const u in authorsNumbers) {
                authorsArr.push({ user: u, quotes: authorsNumbers[u] });
            }
            authorsArr.sort((a: any, b: any) => (a.quotes < b.quotes ? 1 : -1));
            setQuotees(authorsArr);
        }
    }, [filteredQuotes]);
    return (
        <>
            <div className="admin-btn" onClick={handleAuthentication}>
                {user && <img src={`https://cdn.discordapp.com/avatars/${user.id}/${user.avatar}.png`}></img>}
                <span>{authenticated && user ? `${user.username}` : "Log in"}</span>
            </div>
            {admin && (
                <ul className="admin-menu">
                    <li onClick={() => handleViewChange("quotes")}>Quotes</li>
                    <li onClick={() => handleViewChange("logs")}>Pixbot log</li>
                </ul>
            )}
            {view === "logs" && <Logs />}
            {view === "quotes" && (
                <>
                    <h1>Dunderpatrullen quotes</h1>
                    <Helmet>
                        <title>{title}</title>
                        <meta name="description" content="quotes from the Dunderpatrullen Discord"></meta>
                    </Helmet>
                    {users && !random && !single && (
                        <div className="filters">
                            <Select
                                options={userOptions}
                                className="select"
                                placeholder="select a user"
                                styles={selectStyle}
                                onChange={handleSelectUser}
                            />
                            <span style={{ marginTop: ".8rem", color: "#FFF" }}>- or -</span>
                            <Select
                                options={channelOptions}
                                className="select"
                                placeholder="select a channel"
                                styles={selectStyle}
                                onChange={handleSelectChannel}
                            />
                        </div>
                    )}
                    {filteredQuotes && quotees && quoters && (
                        <>
                            <TopList quotees={quotees} quoters={quoters} />
                            <div className="cards">
                                {!random &&
                                    filteredQuotes.map((q: any, i: number) => (
                                        <Card
                                            isSingle={single}
                                            key={`card-${i}`}
                                            ckey={i}
                                            quote={q}
                                            users={data.users}
                                            channels={data.channels}
                                            admin={admin}
                                            token={token}
                                        />
                                    ))}
                                {random && (
                                    <Card
                                        isSingle={single}
                                        ckey={1}
                                        quote={random}
                                        users={data.users}
                                        channels={data.channels}
                                        admin={admin}
                                        token={token}
                                    />
                                )}
                            </div>
                        </>
                    )}
                    {single && (
                        <div
                            className="showAll"
                            onClick={() => {
                                document.location.href = "/";
                            }}>
                            Show all quotes
                        </div>
                    )}
                </>
            )}
        </>
    );
};
