import { CSSProperties, useEffect, useState } from "react";
import SyntaxHighlighter from "react-syntax-highlighter";
import { darcula } from "react-syntax-highlighter/dist/esm/styles/hljs";
import "./logs.css";

export const Logs = (props: any) => {
    const [log, setLog] = useState<string>("");

    const getLogs = async () => {
        const r = await fetch("/.netlify/functions/api-proxy/log");
        const text = await r.text();
        console.log(text);
        setLog(text);
    };

    useEffect(() => {
        if (!log) {
            getLogs();
        }
        if (log) {
            const logWin = document.getElementById("logs");
            logWin?.scrollTo(0, logWin.scrollHeight);
        }
    }, [log]);

    const style: React.CSSProperties = {
        height: "500px",
        margin: "0 auto",
        borderRadius: ".5rem",
        overflow: "hidden",
    };
    return (
        <>
            <h1>Logs</h1>
            <div className="col" style={{ width: "80%", margin: "0 auto" }}>
                <div className="logContainer" style={style}>
                    <SyntaxHighlighter
                        id="logs"
                        language="bash"
                        style={darcula}
                        customStyle={{ height: "100%", margin: "0px" }}>
                        {log}
                    </SyntaxHighlighter>
                </div>
                <div className="log-refresh-btn" onClick={getLogs}>
                    Refresh logs
                </div>
            </div>
        </>
    );
};
